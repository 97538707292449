import {
  ActionIcon,
  Button,
  Container,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { useAdminSubmissionsQuery } from '../hooks/useAdminSubmissionsQuery';
import { SubmissionPagination } from '../components/submission-pagination';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import useDebounce from '../hooks/useDebounce';
import iagService from '../service/iag.service';

export const AdminSubmissionsPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500);
  const [page, setPage] = useState<number>(1);
  const { data, isLoading } = useAdminSubmissionsQuery({
    page: page,
    search: debouncedSearchQuery,
  });
  const pagination = data?.pagination;
  const onNext = () => {
    if (pagination?.hasNextPage) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const onPrevious = () => {
    if (pagination?.hasPreviousPage) {
      setPage(prevPage => prevPage - 1);
    }
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Container size="xl" sx={{ marginTop: '2rem' }}>
        <Paper
          shadow="xl"
          sx={{
            padding: '2rem',
          }}
        >
          <Title order={2}>Submissions - Registrations</Title>
          <Divider orientation="horizontal" size={'md'} variant="solid" sx={{ height: '1rem' }} color="gray" />

          <Group mb="md">
            <form>
              <TextInput
                sx={{
                  width: '20rem',
                }}
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                placeholder="Search Query (min of 3 characters)"
                rightSection={
                  <>
                    {debouncedSearchQuery.length < 3 ? (
                      <ActionIcon variant="transparent" color="blue">
                        <FontAwesomeIcon icon={faSearch} />
                      </ActionIcon>
                    ) : (
                      <ActionIcon variant="filled" color="gray" onClick={() => setSearchQuery('')}>
                        <FontAwesomeIcon icon={faX} />
                      </ActionIcon>
                    )}
                  </>
                }
              />
            </form>
            <Button component="a" href={iagService.ExportSubmissions()} target="_blank">
              Export Submissions
            </Button>
            <Button component="a" href={iagService.ExportTradeData()} target="_blank">
              Export Trade Data
            </Button>
            {/* 
            <Button>Export Trade Data</Button> */}
          </Group>
          <Container
            sx={{
              overflowX: 'scroll',
            }}
            fluid
            px="0"
          >
            <Table
              border={1}
              highlightOnHover
              verticalSpacing="xs"
              style={{ whiteSpace: 'nowrap', border: '1px solid #dfe1e2' }}
              className="submission-table"
            >
              <thead>
                <tr>
                  {/* <th className="submission-table-header-name">RGM ID</th> */}
                  <th className="submission-table-header-name">Legal Entity</th>
                  <th className="submission-table-header-name">Contact Email</th>
                  <th className="submission-table-header-name">Contact Person</th>
                  <th className="submission-table-header-name">Status</th>
                  <th className="submission-table-header-name">View</th>
                </tr>
              </thead>
              <tbody>
                {data?.submissions.map(s => (
                  <tr key={s.id}>
                    {/* <td>{s.rgm}</td> */}
                    <td
                      style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                        width: '300px',
                      }}
                    >
                      {s.legalEntity}
                    </td>
                    <td>{s.email}</td>
                    <td>{`${s.firstName} ${s.lastName}`}</td>
                    <td>{s.status === 'DRAFTED' ? 'DRAFT' : 'SUBMITTED'}</td>
                    <td>
                      <Button
                        size="xs"
                        variant="outline"
                        component="a"
                        href={`/app/submission/${s.id}/view`}
                        target="_blank"
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
          <SubmissionPagination currentPage={page} onNext={onNext} onPrevious={onPrevious} />
        </Paper>
      </Container>
    </>
  );
};
